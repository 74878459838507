import React, { useState } from 'react';
import { _beforeUploadFile, _getFile } from '../../lib/Helper';
import Dragger from 'antd/lib/upload/Dragger';
import { InboxOutlined } from '@ant-design/icons';


const UploadFile = ({ ...props }) => {

  const [fileList, setFileList] = useState([])
  const [validateFile, setValidateFile] = useState(false)

  const handleChangeFile = async ({ fileList }) => {
    if (validateFile === true) {
      fileList.forEach((_, i) => {
        fileList[i].status = "done"
      });

      setFileList(fileList)
    } else {
      setFileList([])
    }

    props.onChange(await _getFile(fileList))
  };

  const beforeUploadFile = (file) => {
    if (!_beforeUploadFile(file)) {
      setValidateFile(false)
      return false
    } else {
      setValidateFile(true)
    }
    return true
  };

  return (
    <Dragger
      action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
      maxCount={1}
      fileList={fileList}
      onChange={handleChangeFile}
      beforeUpload={beforeUploadFile}
    >
      <p className="ant-upload-drag-icon">
        <InboxOutlined />
      </p>
      <p className="ant-upload-text">Klik atau seret file ke area ini</p>
      <p className="ant-upload-hint">File yang diperbolehkan untuk diunggah: .PDF</p>
      <p className="ant-upload-hint">File yang diperbolehkan maksimal 2MB</p>
    </Dragger>
  );
};
export default UploadFile;
