import validate from 'validate.js';

export const schemaDataDiri = {
    nip: {
        presence: { allowEmpty: false, message: 'harus diisi' },
        format: {
            pattern: /^$|[0-9]+/,
            message: function () {
                return validate.format("^NIP harus berupa agka");
            }
        },
    },
    nik: {
        presence: { allowEmpty: false, message: 'harus diisi' },
        format: {
            pattern: /^$|[0-9]+/,
            message: function () {
                return validate.format("^NIK harus berupa agka");
            }
        },
    },
    npk: {
        format: {
            pattern: /^$|[0-9]+/,
            message: function () {
                return validate.format("^NPK harus berupa agka");
            }
        },
    },
    nuptk: {
        format: {
            pattern: /^$|[0-9]+/,
            message: function () {
                return validate.format("^NUPTK harus berupa agka");
            }
        },
    },
    jumlah_jam_mengajar: {
        format: {
            pattern: /^$|[0-9]+/,
            message: function () {
                return validate.format("^Jumlah jam mengajar harus berupa agka");
            }
        },
    },
    nama_lengkap: {
        presence: { allowEmpty: false, message: 'harus diisi' },
        length: {
            maximum: 200
        },
        // format: {
        //     pattern: /^$|[a-zA-Z\s]+/,
        //     message: function () {
        //         return validate.format("^Nama lengkap harus berupa huruf");
        //     }
        // },
    },
    tgl_lahir: {
        presence: { allowEmpty: false, message: 'harus diisi' },
    },
    jumlah_anak: {
        format: {
            pattern: /^$|[0-9]+/,
            message: function () {
                return validate.format("^Jumlah anak harus berupa agka");
            }
        },
    },
    no_telp: {
        length: {
            maximum: 14,
            message: "terlalu panjang (maksimal 14 karakter)"
        },
        format: {
            pattern: /^$|(\+62 ((\d{3}([ -]\d{3,})([- ]\d{4,})?)|(\d+)))|(\(\d+\) \d+)|\d{3}( \d+)+|(\d+[ -]\d+)|\d+/,
            message: function (value) {
                return validate.format("^No telp %{phone} tidak valid", {
                    phone: value
                });
            }
        },
    },
    email: {
        presence: { allowEmpty: false, message: 'harus diisi' },
        format: {
            pattern: /^$|\S+@\S+\.\S+/,
            message: function (value) {
                return validate.format("^Email %{email} tidak valid", {
                    email: value
                });
            }
        },
    },
    kode_pos: {
        length: {
            maximum: 6,
            message: "terlalu panjang (maksimal 6 karakter)"
        },
        format: {
            pattern: /^$|[0-9]+/,
            message: function () {
                return validate.format("^Kode pos harus berupa agka");
            }
        },
    },
    lat: {
        format: {
            pattern: /^$|^[0-9 ()+-]+(\.([0-9]{1,20}))?$/,
            message: function () {
                return validate.format("^Latitude belum sesuai");
            }
        },
    },
    long: {
        format: {
            pattern: /^$|^[0-9 ()+-]+(\.([0-9]{1,20}))?$/,
            message: function () {
                return validate.format("^Longitude belum sesuai");
            }
        },
    },
}

export const schemaTugasUtama = {
    jtm: {
        presence: { allowEmpty: false, message: 'harus diisi' },
        format: {
            pattern: /^$|[0-9]+/,
            message: function () {
                return validate.format("^Jumlah siswa harus berupa agka");
            }
        },
    },
    // jml_siswa: {
    //     format: {
    //         pattern: /^$|[0-9]+/,
    //         message: function () {
    //             return validate.format("^Jumlah siswa harus berupa agka");
    //         }
    //     },
    // },
}

export const schemaTugasTambahanSatminkal = {
    jtm: {
        presence: { allowEmpty: false, message: 'harus diisi' },
        format: {
            pattern: /^$|[0-9]+/,
            message: function () {
                return validate.format("^Jumlah siswa harus berupa agka");
            }
        },
    },
    jml_siswa: {
        format: {
            pattern: /^$|[0-9]+/,
            message: function () {
                return validate.format("^Jumlah siswa harus berupa agka");
            }
        },
    },
}
export const schemaTugasTambahanNonSatminkal = {
    tahun_ajaran_id: {
        presence: { allowEmpty: false, message: 'harus dipilih' },
    },
    jenjang_pendidikan: {
        presence: { allowEmpty: false, message: 'harus dipilih' },
    },
    nama_sekolah: {
        presence: { allowEmpty: false, message: 'harus diisi' },
    },
    jenis_aktivitas: {
        presence: { allowEmpty: false, message: 'harus diisi' },
    },
    // jenis_tugas: {
    //     presence: { allowEmpty: false, message: 'harus diisi' },
    // },
    npsn: {
        format: {
            presence: { allowEmpty: false, message: 'harus diisi' },
            pattern: /^$|[0-9]+/,
            message: function () {
                return validate.format("^Npsn harus berupa agka");
            }
        },
    },
    jtm: {
        // presence: { allowEmpty: false, message: 'harus diisi' },
        format: {
            pattern: /^$|[0-9]+/,
            message: function () {
                return validate.format("^Jumlah siswa harus berupa agka");
            }
        },
    },
    jml_siswa: {
        // presence: { allowEmpty: false, message: 'harus diisi' },
        format: {
            pattern: /^$|[0-9]+/,
            message: function () {
                return validate.format("^Jumlah siswa harus berupa agka");
            }
        },
    },
}

export const schemaStatusRiwayatKepegawaian = {
    jenis_perubahan: {
        presence: { allowEmpty: false, message: 'harus dipilih' },
    },
    instansi_induk: {
        presence: { allowEmpty: false, message: 'harus diisi' },
    },
    tgl_efektif: {
        presence: { allowEmpty: false, message: 'harus diisi' },
    },
    jabatan: {
        presence: { allowEmpty: false, message: 'harus dipilih' },
    },
    status_penugasan: {
        presence: { allowEmpty: false, message: 'harus dipilih' },
    },
    pangkat: {
        presence: { allowEmpty: false, message: 'harus dipilih' },
    },
    status_keaktifan: {
        presence: { allowEmpty: false, message: 'harus dipilih' },
    },
    // no_sk: {
    //     format: {
    //         pattern: /^$|[0-9]+/,
    //         // message: function (value, attribute, validatorOptions, attributes, globalOptions) {
    //         message: function () {
    //             return validate.format("^No.SK harus berupa agka");
    //         }
    //     },
    // },
}

export const schemaPendidikan = {
    jenjang: {
        presence: { allowEmpty: false, message: 'harus dipilih' },
    },
    nama: {
        presence: { allowEmpty: false, message: 'harus diisi' },
    },
    status: {
        presence: { allowEmpty: false, message: 'harus dipilih' },
    },
    tahun_masuk: {
        presence: { allowEmpty: false, message: 'harus dipilih' },
    },
    tahun_keluar: {
        presence: { allowEmpty: false, message: 'harus dipilih' },
    },
    indeks_prestasi: {
        format: {
            pattern: /^$|[0-9]+/,
            message: function () {
                return validate.format("^Index Prestasi harus berupa agka");
            }
        },
    },
    // nomor_ijazah: {
    //     format: {
    //         pattern: /^$|[0-9]+/,
    //         message: function () {
    //             return validate.format("^No.Ijazah harus berupa agka");
    //         }
    //     },
    // },
}

export const schemaPelatihan = {
    nama: {
        presence: { allowEmpty: false, message: 'harus diisi' },
    },
    bidang: {
        presence: { allowEmpty: false, message: 'harus dipilih' },
    },
    penyelenggara: {
        presence: { allowEmpty: false, message: 'harus dipilih' },
    },
    tahun: {
        presence: { allowEmpty: false, message: 'harus dipilih' },
    },
    // nomor_sertifikat: {
    //     format: {
    //         pattern: /^$|[0-9]+/,
    //         message: function () {
    //             return validate.format("^No Sertifikat harus berupa agka");
    //         }
    //     },
    // },
}

export const schemaSertifikasi = {
    status_sertifikasi: {
        presence: { allowEmpty: false, message: 'harus diisi' },
    },
    tahun_sertifikasi: {
        presence: { allowEmpty: false, message: 'harus diisi' },
    },
    status_kelulusan: {
        presence: { allowEmpty: false, message: 'harus diisi' },
    },
    nomor_sertifikat: {
        format: {
            pattern: /^$|[0-9]+/,
            message: function () {
                return validate.format("^No Sertifikat harus berupa agka");
            }
        },
    },
}

export const schemaPenghargaan = {
    tahun: {
        presence: { allowEmpty: false, message: 'harus diisi' },
    },
    nama: {
        presence: { allowEmpty: false, message: 'harus diisi' },
    },
    jenis: {
        presence: { allowEmpty: false, message: 'harus diisi' },
    },
    penyelenggara: {
        presence: { allowEmpty: false, message: 'harus diisi' },
    },
    tingkat: {
        presence: { allowEmpty: false, message: 'harus diisi' },
    }
}

export const schemaAnak = {
    nama: {
        presence: { allowEmpty: false, message: 'harus diisi' },
    },
    jenis_kelamin: {
        presence: { allowEmpty: false, message: 'harus diisi' },
    },
    status: {
        presence: { allowEmpty: false, message: 'harus diisi' },
    },
}

export const schemaPesantren = {
    konsentrasi: {
        presence: { allowEmpty: false, message: 'harus diisi' },
    },
    lama_pendidikan: {
        presence: { allowEmpty: false, message: 'harus diisi' },
    },
    tgl_mulai: {
        presence: { allowEmpty: false, message: 'harus diisi' },
    },
    tgl_lulus: {
        presence: { allowEmpty: false, message: 'harus diisi' },
    },
}

export const schemaGuruDelete = {
    tgl_keluar: {
        presence: { allowEmpty: false, message: 'harus diisi' },
    },
    alasan_keluar: {
        presence: { allowEmpty: false, message: 'harus diisi' },
    },
}
